



































import { Getter, Action } from "vuex-class";
import { Component, Vue, Prop, Ref, Watch } from "vue-property-decorator";
import { FormBase, FormInput, FormTextarea, FormSelect, FormError, FormSubmit } from "@/components/forms";

@Component({
  components: {
    LayoutModal: () => import("@/layouts/components/modal.vue"),
    FormBase,
    FormInput,
    FormTextarea,
    FormSelect,
    FormError,
    FormSubmit,
  },
})
export default class ModalHunchCreate extends Vue {
  @Getter("workspace/viewing") workspace!: Workspace;

  @Action("hunch/create") createHunch!: (payload: HunchPayload) => Promise<Hunch>;

  @Prop() open!: boolean;

  @Ref() form!: FormClass;

  typeOptions: SelectOption[] = [
    { id: 1, label: "Idea", icon: "icon-idea", value: { slug: "idea" } },
    { id: 2, label: "Challenge", icon: "icon-challenge", value: { slug: "challenge" } },
    { id: 3, label: "Opinion", icon: "icon-opinion", value: { slug: "opinion" } },
    { id: 4, label: "Question", icon: "icon-question", value: { slug: "question" } },
  ];

  errorResponse: ErrorResponse = {
    status: 0,
    errors: [],
  };

  type = NaN;

  payload: HunchPayload = {
    title: "",
    type: "",
    workspace_id: NaN,
  };

  private async submit() {
    this.payload.workspace_id = this.workspace.id;
    this.createHunch(this.payload)
      .then((hunch: Hunch) => {
        this.$router.push({ name: "hunches-detail", params: { id: `${hunch.id}` } });
        this.$emit("close");
      })
      .catch((errorResponse: ErrorResponse) => (this.errorResponse = errorResponse));
  }

  private selectType() {
    this.payload.type = this.typeOptions.find((option: SelectOption) => option.id === this.type)?.value?.slug || "";
  }

  @Watch("open")
  handleOpen(open: boolean) {
    if (open) {
      this.payload = {
        title: "",
        type: "",
        description: "",
        workspace_id: NaN,
      };
    }
  }
}
